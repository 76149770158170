@media screen {
  button#printButton {
    border: 1px solid #4caf50 !important;
    border-radius: 5px;
    padding: 10px 20px;
    background: #4caf50 !important;
    color: white;
    box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2),
      0 1px 5px 0 rgba(76, 175, 80, 0.12);
    background-color: #4caf50;
  }
  button#printButton:hover {
    box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2),
      0 1px 5px 0 rgba(76, 175, 80, 0.12) !important;
    background-color: #4caf50 !important;
    transition: 0.7s !important;
  }

  .thermal.preview-container {
    width: 68mm;
    background: white;
    margin: 2em auto;
    padding: 4mm;
    border: 1px dashed #ccc;
    font-family: 'IBM Plex Mono', monospace;
  }

  .screen-only {
    display: block;
  }
}

@media print {
  .letter {
    margin: 2em;
  }

  .hide-on-print,
  .screen-only {
    display: none !important;
  }

  .letter #bol-details > p {
    margin: 1px;
  }
  .letter .legal-text {
    font-size: 9px;
    line-height: 1em;
    margin-bottom: 0;
    margin-block-end: 4px;
    margin-block-start: 4px;
  }
  .letter .inventory-table {
    margin-bottom: 10px;
  }

  body .thermal {
    width: 72mm;
    margin: 0;
    padding: 2mm;
  }

  .thermal.preview-container {
    width: 68mm;
    margin: 0;
    padding: 2mm;
    font-family: 'IBM Plex Mono', monospace;
  }
}

.print-controls {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.letter .sigContainer {
  width: 200px;
}
.letter .sigContainer svg {
  width: 100%;
  height: auto;
}

.thermal {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.2;
}

.thermal .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4px;
}

.thermal .title p {
  font-weight: bold;
  margin: 0;
  font-size: 8px;
}

.thermal .logo {
  display: flex;
  justify-content: center;
}

.thermal .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  border-bottom: 1px dashed #000;
  padding-bottom: 4px;
}

.thermal .bol-number {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.thermal .bol-number .label {
  font-weight: bold;
}

.thermal .date-time {
  margin: 0;
  text-align: right;
  font-size: 10px;
}

.thermal .basic-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 10px;
  margin: 4px 0;
  border-bottom: 1px dashed #000;
  padding-bottom: 6px;
}

.thermal .basic-info p {
  margin: 0;
  line-height: 1.2;
}

.thermal .info-label {
  font-weight: normal;
}

.thermal .table-container {
  margin: 6px 0;
  border-bottom: 1px dashed #000;
  padding-bottom: 6px;
}

.thermal-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  overflow: hidden;
}

.thermal-table th,
.thermal-table td {
  font-size: 10px;
  padding: 2px 2px !important;
  border: none !important;
  border-bottom: 1px solid #ddd !important;
  font-family: 'IBM Plex Mono', monospace;
}

.thermal-table th {
  font-weight: bold;
}

.thermal-table .subtotal-row {
  border-top: 1px solid black;
}

.thermal-table .subtotal-row td,
.thermal-table .subtotal-row th {
  padding-top: 4px;
}

.thermal-table td strong {
  font-weight: bold;
}

.thermal .qr-section {
  display: flex;
  justify-content: center;
  margin: 6px 0;
}

.thermal .qr-code {
  width: 72mm !important;
  height: 72mm !important;
}

.thermal .warning-section {
  border-top: 1px dashed #000;
  border-bottom: 1px dashed #000;
  padding: 4px 0;
  margin: 6px 0;
  text-align: center;
}

.thermal .warning-section p {
  margin: 0;
  font-size: 12px;
}

.thermal .signatures {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin: 6px 0;
}

.thermal .signature-section {
  flex: 1;
}

.thermal .signature-title {
  font-size: 10px;
  font-weight: bold;
  margin: 0 0 2px 0;
  text-align: center;
}

.thermal .signature-box {
  border: 1px solid #000;
  padding: 6px;
  text-align: center;
  min-height: 30px;
  margin-bottom: 2px;
  font-size: 10px;
}

.thermal .legal-section {
  font-size: 7px;
  margin-bottom: 6px;
  border-bottom: 1px dashed #000;
  padding-bottom: 4px;
}

.thermal .legal-section p {
  margin: 2px 0;
}

.thermal .certification-text {
  font-size: 8px;
}
