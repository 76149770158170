.MuiFormControl-root.MuiTextField-root.undefined.CreateBOLForm-getBold-89.MuiFormControl-fullWidth {
  margin: 16px 0 8px;
}

.cardCategoryWhite {
  color: rgba(255,255,255,.62);
  margin: 0;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}

.cardTitleWhite {
  color: #FFFFFF;
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin-bottom: 3px;
  text-decoration: none;
}

.getBold {
  font-weight: bold;
}

.addMarginTop {
  margin-top: 30px;
}

.addMarginLeft {
  margin-left: 4px;
}

.GridContainer {
  margin: 0 -15px !important;
  width: unset;
}

.GridItem {
  padding: 0 15px !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  overflow: visible !important;
  white-space: normal !important;
}